import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import Suffix from '@tebuto/forms/Suffix'
import { Field, useFormikContext } from 'formik'
import { Tooltip } from 'react-tooltip'
import Description from './Description'
import ErrorMessage from './ErrorMessage'
import { Label } from './Label'

export interface IFormField {
    name: string
    label?: string
    autoComplete?: string
    type?: string
    className?: string
    fieldClassName?: string
    suffix?: string
    mandatory?: boolean
    description?: string
    placeholder?: string
    readOnly?: boolean
    showErrors?: boolean
    labelTooltip?: string
    height?: 'default' | 'full'
}

export default function FormField({
    className,
    fieldClassName,
    label,
    name,
    autoComplete,
    suffix,
    type,
    labelTooltip,
    description,
    placeholder,
    mandatory,
    readOnly,
    showErrors = true,
    height = 'default'
}: IFormField): JSX.Element {
    const { errors, touched } = useFormikContext()

    function CSSClasses() {
        let defaultClasses =
            'rounded-lg flex-1 min-w-0 block w-full px-2 py-1 border border-gray-300 text-gray-500 shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 placeholder:text-gray-300'
        if (readOnly) defaultClasses += ' hover:cursor-not-allowed '
        // @ts-ignore
        if (errors[name] && touched[name]) defaultClasses += ' border-red-500 '
        if (suffix) defaultClasses += ' rounded-r-none '
        return defaultClasses
    }

    return (
        <div className={`${className} relative`}>
            {label ? (
                <div className="flex justify-between">
                    <Label htmlFor={name} mandatory={mandatory}>
                        {label}
                    </Label>
                    {labelTooltip ? <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400 inline" data-tooltip-id={name} data-tooltip-content={labelTooltip} /> : <></>}
                </div>
            ) : (
                <></>
            )}
            <div className={`flex rounded-md shadow-sm ${height === 'default' ? ' h-10' : 'h-full'}`}>
                <Field
                    data-tooltip-target="tooltip-default"
                    id={name}
                    name={name}
                    type={type ? type : 'text'}
                    readOnly={readOnly}
                    disabled={readOnly}
                    autoComplete={autoComplete}
                    className={fieldClassName ?? CSSClasses()}
                    required={mandatory}
                    placeholder={placeholder}
                />
                {suffix && <Suffix suffix={suffix} />}
            </div>
            {labelTooltip ? <Tooltip id={name} /> : <></>}
            {showErrors ? <ErrorMessage name={name} /> : <></>}
            <Description description={description} />
        </div>
    )
}
