'use client'

import FormButtons from '@tebuto/forms/Buttons'
import FormCheckbox from '@tebuto/forms/Checkbox'
import ErrorMessage from '@tebuto/forms/ErrorMessage'
import FormField from '@tebuto/forms/Field'
import { UNICODE_EMAIL_REGEX } from '@tebuto/functions'
import Container from '@tebuto/layout/Container'
import { Form, Formik } from 'formik'
import { motion, useAnimation, useInView } from 'framer-motion'
import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'
import { boolean, object, string } from 'yup'
import { TRANSLATIONS } from './translations'

export default function Waitlist(): JSX.Element {
    const [formSent, setFormSent] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const ref = useRef(null)
    const inView = useInView(ref, { once: true, amount: 1 })
    const controls = useAnimation()

    const waitlistFormValidationSchema = object().shape({
        email: string().required(TRANSLATIONS.landing.waitlist.errors.emailMissing).matches(UNICODE_EMAIL_REGEX, {
            message: TRANSLATIONS.landing.waitlist.errors.emailInvalid,
            name: 'email',
            excludeEmptyString: true
        }),
        agreeToDataPrivacy: boolean().isTrue(TRANSLATIONS.landing.waitlist.errors.agreementMissing)
    })

    useEffect(() => {
        if (inView) {
            controls.start({ opacity: 1 })
        } else {
            controls.start({ opacity: 0 })
        }
    }, [inView, controls])

    async function send(values: WaitlistFormValues) {
        try {
            await fetch(`${process.env.NEXT_PUBLIC_BFF_BASE_URL}/waitlist`, {
                method: 'POST',
                body: JSON.stringify({
                    email: values.email
                })
            })
        } catch (error) {
            console.error(TRANSLATIONS.landing.waitlist.errors.generic, error)
            setError(TRANSLATIONS.landing.waitlist.errors.generic)
        } finally {
            setFormSent(true)
        }
    }

    return (
        <Container id="warteliste" ignorePaddingX className="xl:py-32 py-16">
            <div className="relative isolate flex flex-col gap-10 overflow-hidden bg-gradient-primary dark:shadow-[0_0_100px_#004844] dark:bg-gradient-primary-dark px-6 py-24 sm:rounded-3xl sm:px-24 xl:flex-row xl:items-center xl:py-32">
                <h2 ref={ref} className="max-w-2xl text-xl font-bold tracking-tight text-white sm:text-2xl xl:max-w-none xl:flex-auto">
                    <motion.div initial={{ opacity: 0, fillOpacity: 0 }} animate={controls} transition={{ duration: 2, ease: 'easeIn' }}>
                        {TRANSLATIONS.landing.waitlist.CTAPart1}
                        <span className="font-normal block mt-2 text-primary-100">{TRANSLATIONS.landing.waitlist.CTAPart2}</span>
                    </motion.div>
                </h2>

                <Formik
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        email: '',
                        message: '',
                        agreeToDataPrivacy: false
                    }}
                    validateOnChange={false}
                    validateOnBlur={true}
                    validationSchema={waitlistFormValidationSchema}
                    onSubmit={send}
                >
                    {({ dirty, isSubmitting, errors: validationErrors }) => {
                        if (formSent) {
                            return (
                                <div className="text-sm text-center">
                                    {error ? <p className="text-red-400">{error}</p> : <p className="text-white">{TRANSLATIONS.landing.waitlist.successMessage}</p>}
                                </div>
                            )
                        }

                        return (
                            <Form className="relative w-full max-w-md">
                                <div className="grid grid-cols-4">
                                    <FormField
                                        name={'email'}
                                        type={'email'}
                                        autoComplete={'email'}
                                        placeholder={TRANSLATIONS.landing.waitlist.emailPlaceholder}
                                        className={'col-span-3'}
                                        fieldClassName={
                                            'min-w-0 h-full flex-auto rounded-md border border-primary-100 dark:border-primary-600 placeholder-white dark:placeholder-primary-600 bg-white/5 dark:bg-primary-800/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6'
                                        }
                                        mandatory={true}
                                        showErrors={false}
                                        height={'full'}
                                    />
                                    <FormButtons
                                        showResetButton={false}
                                        saveButtonEnabled={dirty && !isSubmitting}
                                        saveButtonLabel={TRANSLATIONS.landing.waitlist.register}
                                        submitButtonClassName={`col-span-1 bg-transparent border shadow-sm text-sm font-semibold ${
                                            isSubmitting
                                                ? 'text-gray-300 border-gray-300'
                                                : 'text-white !border-primary-100 dark:text-primary-200 dark:border-primary-200 hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white'
                                        }`}
                                    />
                                </div>
                                <div className="relative flex items-start pt-4">
                                    <FormCheckbox name={'agreeToDataPrivacy'} mandatory={true} showErrors={false} />
                                    <div className="ml-3 text-sm leading-6">
                                        <label htmlFor="agreeToDataPrivacy" className="text-primary-50 dark:text-primary-100 block">
                                            Ich stimme der Verarbeitung meiner Daten zum Zweck der Kontaktaufnahme durch Tebuto zu.{' '}
                                            <Link href="/datenschutzerklaerung" className="text-primary-100 dark:text-primary-300 underline">
                                                Datenschutzerklärung
                                            </Link>
                                        </label>
                                        <div className="relative">
                                            <ErrorMessage name={'email'} />
                                            {validationErrors.agreeToDataPrivacy && !validationErrors.email && <ErrorMessage name={'agreeToDataPrivacy'} />}
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>

                <svg viewBox="0 0 1024 1024" className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 dark:brightness-75" aria-hidden="true">
                    <title>Background Flare</title>
                    <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                    <defs>
                        <radialGradient
                            id="759c1415-0410-454c-8f7c-9a820de03641"
                            cx={0}
                            cy={0}
                            r={1}
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(512 512) rotate(90) scale(512)"
                        >
                            <stop stopColor="#E9F9F8" />
                            <stop offset={1} stopColor="#009087" stopOpacity={0} />
                        </radialGradient>
                    </defs>
                </svg>
            </div>
        </Container>
    )
}

interface WaitlistFormValues {
    email: string
    agreeToDataPrivacy: boolean
}
