interface DescriptionProps {
    description?: string
}

export default function Description({ description }: DescriptionProps): JSX.Element {
    if (description) {
        return <p className="text-xs mt-1 text-gray-400 ml-1 leading-tight">{description}</p>
    }
    return <></>
}
