import { ErrorMessage } from 'formik'

interface ErrorMessageProps {
    name: string
    className?: string
}

export default function FormErrorMessage({ name, className = '' }: ErrorMessageProps): JSX.Element {
    return <ErrorMessage component="span" name={name} className={`text-xs text-red-400 mt-2 ${className}`} />
}
