interface SuffixProps {
    suffix?: string
}

export default function Suffix({ suffix }: SuffixProps): JSX.Element {
    if (suffix) {
        return (
            <span className="inline-flex items-center text-xs px-3 rounded-r-md border border-l-0 border-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 bg-gray-200 text-gray-600 sm:text-sm">
                {suffix}
            </span>
        )
    }
    return <></>
}
