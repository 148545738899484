'use client'

import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import Container from '@tebuto/layout/Container'
import Heading from '@tebuto/typography/Heading'
import { TRANSLATIONS } from './translations'

type QuestionAndAnswer = {
    question: string
    answer: string
}

export default function FAQ(): JSX.Element {
    const questionsAndAnswers = TRANSLATIONS.landing.FAQ.items as QuestionAndAnswer[]
    return (
        <Container id="faq" className="py-32">
            <div className="mx-auto max-w-4xl divide-y divide-gray-900/10 my-16">
                <Heading level={2}>{TRANSLATIONS.landing.FAQ.title}</Heading>
                <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                    {questionsAndAnswers.map(faq => (
                        <Disclosure as="div" key={faq.question} className="pt-6">
                            {({ open }) => (
                                <>
                                    <dt>
                                        <DisclosureButton className="flex w-full items-start justify-between text-left">
                                            <span className="text-xl font-semibold leading-7 text-gray-700">{faq.question}</span>
                                            <span className="ml-6 flex h-7 items-center">
                                                {open ? <MinusIcon className="h-6 w-6" aria-hidden="true" /> : <PlusIcon className="h-6 w-6" aria-hidden="true" />}
                                            </span>
                                        </DisclosureButton>
                                    </dt>
                                    <DisclosurePanel as="dd" className="mt-2 pr-12">
                                        <p className="text-lg mt-6 leading-7 ">{faq.answer}</p>
                                    </DisclosurePanel>
                                </>
                            )}
                        </Disclosure>
                    ))}
                </dl>
            </div>
        </Container>
    )
}
