'use client'

import { classNames } from '@tebuto/functions'
import { ButtonSpinner } from '@tebuto/layout/Spinner'
import COMPONENT_TEXTS from '@tebuto/translations'

interface FormButtonProps {
    loading?: boolean
    showResetButton?: boolean
    resetButtonEnabled?: boolean
    saveButtonEnabled?: boolean
    resetButtonLabel?: string
    saveButtonLabel?: string
    className?: string
    submitButtonClassName?: string
    resetButtonClassName?: string
}

export default function FormButtons({
    loading = false,
    showResetButton = true,
    resetButtonEnabled = true,
    saveButtonEnabled = true,
    resetButtonLabel = COMPONENT_TEXTS.forms.reset,
    saveButtonLabel = COMPONENT_TEXTS.forms.save,
    className = '',
    submitButtonClassName = '',
    resetButtonClassName = ''
}: FormButtonProps): JSX.Element {
    const generalButtonCss =
        'inline-flex justify-center py-1.5 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 rounded-lg border'

    const submitButtonCss = `text-primary-500 border-primary-500
        ${saveButtonEnabled || loading ? 'hover:bg-primary-600 hover:text-white' : 'opacity-30 hover:cursor-not-allowed'}
        ${showResetButton ? 'ml-5 ' : ''}
        ${submitButtonClassName}
        ${generalButtonCss}
    `

    const resetButtonCss = classNames(
        resetButtonEnabled ? 'border-gray-400 text-gray-500 hover:bg-gray-200  hover:text-gray-700' : 'border-gray-200 text-gray-200',
        resetButtonClassName,
        generalButtonCss
    )

    return (
        <div className={`flex items-center ${className} ${!showResetButton ? 'justify-end' : 'justify-between'}`}>
            {showResetButton && (
                <button type="reset" disabled={!resetButtonEnabled} className={resetButtonCss}>
                    {resetButtonLabel}
                </button>
            )}
            {
                <button type="submit" disabled={!saveButtonEnabled || loading} className={submitButtonCss}>
                    {loading ? <ButtonSpinner /> : saveButtonLabel}
                </button>
            }
        </div>
    )
}
