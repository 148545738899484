'use client'

import Container from '@tebuto/layout/Container'
import Heading from '@tebuto/typography/Heading'
import { motion, useAnimation, useInView } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { TRANSLATIONS } from './translations'

export default function Stats(): JSX.Element {
    const controls = useAnimation()
    const ref = useRef(null)
    const inView = useInView(ref, { once: true, amount: 0.33 })

    const stats = [
        { label: TRANSLATIONS.landing.stats.values.daily.unit, value: TRANSLATIONS.landing.stats.values.daily.value },
        {
            label: TRANSLATIONS.landing.stats.values.yearlyTime.unit,
            value: TRANSLATIONS.landing.stats.values.yearlyTime.value
        },
        {
            label: TRANSLATIONS.landing.stats.values.yearlyMoney.unit,
            value: TRANSLATIONS.landing.stats.values.yearlyMoney.value
        }
    ]

    useEffect(() => {
        if (inView) {
            controls.start({ opacity: 1 })
        } else {
            controls.start({ opacity: 0 })
        }
    }, [inView, controls])

    return (
        <Container className="xl:pb-32 pb-16 xl:pt-64 pt-32">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
                    <div className="my-auto">
                        <Heading level={2}>{TRANSLATIONS.landing.stats.title}</Heading>
                        <div className="lg:w-full lg:max-w-2xl lg:flex-auto mt-6">
                            <p className="xl:text-lg leading-8 ">{TRANSLATIONS.landing.stats.description}</p>
                        </div>
                    </div>
                    <div className="lg:flex lg:flex-auto lg:justify-center relative">
                        <dl ref={ref} className="w-64 space-y-8 xl:w-96">
                            {stats.map((stat, index) => (
                                <motion.div
                                    key={stat.label + stat.value}
                                    ref={ref}
                                    initial={{ opacity: 0 }}
                                    animate={controls}
                                    transition={{ duration: 2, delay: index * 2.5 }}
                                    className="flex flex-col-reverse"
                                >
                                    <dt className="text-base font-light leading-7 ">{stat.label}</dt>
                                    <dd className="xl:text-5xl text-3xl font-bold tracking-tight text-gray-900">{stat.value}</dd>
                                </motion.div>
                            ))}
                        </dl>
                    </div>
                </div>
                <p className="mt-24 xl:text-sm text-xs leading-7 text-gray-700 dark:text-primary-700">{TRANSLATIONS.landing.stats.disclaimer}</p>
            </div>
        </Container>
    )
}
