'use client'

import Container from '@tebuto/layout/Container'
import Heading from '@tebuto/typography/Heading'
import { motion, useAnimation, useInView } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { TRANSLATIONS } from './translations'

interface Feature {
    name: string
    description: string
    href: string
    animationDuration: number
    iconPath: string
}

function AnimatedFeature(feature: Feature): JSX.Element {
    const controls = useAnimation()
    const ref = useRef(null)
    const inView = useInView(ref, { once: true, amount: 0.33 })

    useEffect(() => {
        if (inView) {
            controls.start({ pathLength: 1 })
        } else {
            controls.start({ pathLength: 0 })
        }
    }, [inView, controls])

    return (
        <motion.div key={feature.name} ref={ref} className="flex flex-col rounded-lg text-center xl:px-8 px-4">
            <dt className="text-center relative gap-x-3 text-xl font-medium mt-4 leading-7 text-gray-900">
                <div className="self-center rounded-full max-w-min p-4 ring-primary bg-gradient-primary dark:bg-gradient-primary-dark mx-auto mb-6" ref={ref}>
                    <motion.svg className="h-20 w-20 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-description={feature.name}>
                        <title>{`${feature.name} icon`}</title>
                        <motion.path
                            fill="none"
                            stroke="#E9F9F8"
                            strokeWidth="1"
                            d={feature.iconPath}
                            initial={{ pathLength: 0 }}
                            animate={controls}
                            transition={{ duration: feature.animationDuration, ease: 'easeInOut' }}
                        />
                    </motion.svg>
                </div>
                {feature.name}
            </dt>
            <dd className="mt-10 flex flex-auto flex-col text-base ">
                <p className="text-left hyphens-auto text-base leading-7">{feature.description}</p>
            </dd>
        </motion.div>
    )
}

export default function BaseLineSummary() {
    const features: Feature[] = [
        {
            name: TRANSLATIONS.landing.baseLineSummary.dataPrivacy.title,
            description: TRANSLATIONS.landing.baseLineSummary.dataPrivacy.description,
            href: '#',
            animationDuration: 4,
            iconPath:
                'M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z'
        },
        {
            name: TRANSLATIONS.landing.baseLineSummary.externalFinancing.title,
            description: TRANSLATIONS.landing.baseLineSummary.externalFinancing.description,
            href: '#',
            animationDuration: 6,
            iconPath: 'M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z'
        },
        {
            name: TRANSLATIONS.landing.baseLineSummary.communityDrivenDevelopment.title,
            description: TRANSLATIONS.landing.baseLineSummary.communityDrivenDevelopment.description,
            href: '#',
            animationDuration: 9,
            iconPath:
                'M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z'
        }
    ]

    return (
        <Container id="uebersicht" className="pt-32 pb-32 relative">
            <div className="mx-auto max-w-4xl lg:text-center">
                <Heading level={2}>{TRANSLATIONS.landing.baseLineSummary.title}</Heading>
                <p className="mt-6 xl:text-lg leading-8 ">{TRANSLATIONS.landing.baseLineSummary.description}</p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-18 lg:mt-20 lg:max-w-none ">
                <dl className="grid max-w-xl grid-cols-1 gap-x-12 gap-y-16 lg:max-w-none lg:grid-cols-3">{features.map(AnimatedFeature)}</dl>
            </div>
        </Container>
    )
}
