interface MandatoryProps {
    mandatory?: boolean
}

export default function Mandatory({ mandatory = false }: MandatoryProps): JSX.Element {
    if (mandatory) {
        return <span className="text-sm font-medium text-red-400 absolute -top-1 right-0"> *</span>
    }
    return <></>
}
