import Description from '@tebuto/forms/Description'
import Mandatory from '@tebuto/forms/Mandatory'
import Suffix from '@tebuto/forms/Suffix'
import { Field } from 'formik'
import ErrorMessage from './ErrorMessage'

interface FormCheckboxProps {
    name: string
    label?: string
    autoComplete?: string
    className?: string
    suffix?: string
    mandatory?: boolean
    description?: string
    readonly?: boolean
    showErrors?: boolean
}

export default function FormCheckbox({ className, label, name, autoComplete, suffix, description, mandatory, readonly = false, showErrors }: FormCheckboxProps): JSX.Element {
    function CSSClasses() {
        let defaultClasses = 'h-5 w-5 rounded border-gray-300 text-primary focus:ring-primary mt-0.5 mr-4'
        if (readonly) defaultClasses += 'hover:cursor-not-allowed'
        return defaultClasses
    }

    return (
        <div className={`${className} relative`}>
            <div className="flex">
                <Field name={name} type={'checkbox'} autoComplete={autoComplete} className={CSSClasses()} />
                <div>
                    {label && (
                        <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
                            {label} <Mandatory mandatory={mandatory} />
                        </label>
                    )}
                    <Description description={description} />
                </div>
                <Suffix suffix={suffix} />
            </div>
            {showErrors && <ErrorMessage name={name} />}
        </div>
    )
}
